import React from 'react';
import ProviderPage from '../../components/ProviderPage';

const providerImage = require('./gezondheidshoes.jpg');

const providerID = 'huisartspraktijk-richters-enschede';
const providerName = 'Huisartspraktijk Richters Enschede';
const providerPhoneNumber = '053 431 86 00';
const providerWebsite = 'https://huisartspraktijkrichters.praktijkinfo.nl/';
const providerRemarks =
  `Het is op het moment niet mogelijk om digitaal een afspraak in te plannen, dit omdat de telefonische triage belangrijker is ten gevolge van de Corona maatregelen.

Neemt u telefonisch contact op met de assistente. De verschillende mogelijkheden om een afspraak met de huisarts te maken bespreekt de assistente met u. Uw afspraak met de huisarts kan namelijk ook telefonisch, via Facetalk en eventueel kunt u ook foto’s sturen via beveiligde mail.`;





const providerLocations = {
  gezondheidshoes: {
    name: 'Gezondheidshoes',
    address: 'J.J. Van Deinselaan 34e\n7541 PE Enschede',
    coordinates: {
      lat: 52.207365,
      lng: 6.901736
    }
  }
};

const providerEmployees = {
  anneloesRichters: {
    name: 'Anneloes Richters',
    imageURL: require('./anneloes-richters.png') // eslint-disable-line global-require
  },
  irisGuchelaar: {
    name: 'Iris Guchelaar',
    imageURL: require('./drs-guchelaar.jpg') // eslint-disable-line global-require
  },
  hannieBlok: {
    name: 'Hannie Blok',
    imageURL: require('./hannie-blok.png') // eslint-disable-line global-require
  },
  simoneBroekhuis: {
    name: 'Simone Broekhuis',
    imageURL: require('./simone-broekhuis.png') // eslint-disable-line global-require
  },
  sandraHaarsma: {
    name: 'Sandra Haarsma',
    imageURL: require('./sandra-haarsma.jpg') // eslint-disable-line global-require
  },
  lindaRanselaar: {
    name: 'Linda Ranselaar',
    imageURL: require('./linda-ranselaar.png') // eslint-disable-line global-require
  },

  moiraDommeck: {
    name: 'Moira Dommeck',
    imageURL: require('./moira-dommeck.png') // eslint-disable-line global-require
  },
  ramitaVasterink: {
    name: 'Ramita Vasterink',
    imageURL: require('./ramita-vasterink.jpg') // eslint-disable-line global-require
  },
  carlaDeGraaf: {
    name: 'Carla de Graaf',
    imageURL: require('./carla-de-graaf.png') // eslint-disable-line global-require
  },
  mirandaVerhoef: {
    name: 'Miranda Verhoef',
    imageURL: require('./miranda-verhoef.png') // eslint-disable-line global-require
  }
};

const providerDescription = `Huisartspraktijk Richters is een praktijk die gevestigd is in een modern gezondheidscentrum op het Diekman terrein, het Gezondheidshoes. In de praktijk werkt de huisarts samen met praktijkondersteuners voor chronische zorg (suikerziekte, longziekte, hart- en vaatziekten), ouderenzorg en geestelijke gezondheidszorg. Daarnaast hebben we 3 doktersassistentes, die u uiteraard te woord staan aan de telefoon en aan de balie. Daarnaast zult u ze ook tegenkomen bij verschillende kleine verrichtingen zoals het verwijderen van hechtingen, het uitspuiten van oren of het aanstippen van wratten.

In het Gezondheidshoes vind u verschillende disciplines zoals fysiotherapie, logopedie en een apotheek. Binnen de praktijk werken 2 huisartsen (dokter Richters en dokter Guchelaar) en 1 huisarts in opleiding (Niek van Regteren). Daarnaast werken er 3 doktersassistentes (Hannie, Simone en Sandra) en 3 praktijkondersteuners (Moira, Ramita en Linda).

De huisarts werkte vroeger vaak alleen met een doktersassistente, dat is veranderd, het is nu teamwork. We vinden het echter belangrijk dat u nog altijd laagdrempelig terecht kunt op de praktijk.

Met dit hele team willen we laagdrempelig zorg leveren. U kunt nu heel eenvoudig een afspraak maken bij huisartsenpraktijk Richters in Enschede via Planl. Klik simpelweg op de bovenstaande knop.`;

const providerMetaDescription =
  'Afspraak maken bij de huisartsenpraktijk Richters in het gezondheidshoes in Enschede';
const providerMetaKeywords =
  'Afspraak maken,Huisartsenpraktijk Richters,Richters,Enschede,Huisarts, Anneloes Richters, Novozorg, Novo Zorg, Gezondheidshoes, Huisartspraktijk Richters';

export default function StudentenArtsUtrecht() {
  return (
    <ProviderPage
      providerID={providerID}
      providerName={providerName}
      providerPhoneNumber={providerPhoneNumber}
      providerWebsite={providerWebsite}
      providerImage={providerImage}
      providerRemarks={providerRemarks}
      providerLocations={providerLocations}
      providerEmployees={providerEmployees}
      providerDescription={providerDescription}
      providerMetaDescription={providerMetaDescription}
      providerMetaKeywords={providerMetaKeywords}
      appointmentsDisabled={true}
    />
  );
}
